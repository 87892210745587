export const responseType = {
  TEXT: 'TEXT',
  CALENDER: 'CALENDAR',
  DATETIME: 'DATE/TIME',
  STARRATING: 'STAR RATING',
  SCORERATING: 'SCORE RATING',
  EMOJIRATING: 'EMOJI RATING',
  CHOICE: 'CHOICE',
  DROPDOWN: 'DROPDOWN',
  MULTISELECT: 'MULTISELECT',
  API: 'API',
};

export const ScreenLayout = {
  ONE_FIELD_LAYOUT: '1',
  TWO_FIELDS_LAYOUT: '2',
  THREE_FIELDS_LAYOUT: '3',
  FOUR_FIELDS_LAYOUT: '4',
};

export enum formStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  PREVIEW = 'PREVIEW',
}

export const Regex = {
  ALL_LANGUAGE_WITH_SPECIAL_CHAR: /^[^=!~$%<>`"'#\[\]/\\0-9]*$/,
  ALPHA_NUMERIC_WITH_SPECIAL_CHAR: /^[^=!~$%<>`"'#\[\]/\\]*$/,
  ALL_LANGUAGE_WITH_NO_SPECIAL_CHAR:
    /^[^=!~$%<>`"'@*&_(),.:;|?+^<>#\[\]/\\0-9-]*$/,
  ALPHA_NUMERIC_WITH_NO_SPECIAL_CHAR: /^[^=!~$%<>`"'@*&_(),.:;|?+^<>#\[\]-]*$/,
};

export const questionIdentifier = {
  STATE: 'STATE',
  CITY: 'CITY',
  MODEL: 'MODEL',
  VARIANT: 'VARIANT',
  MOBILE: 'MOBILE',
  NAME: 'NAME',
  COLOR: 'COLOUR',
  DEALER: 'DEALER',
  EXCHANGE: 'EXCHANGE',
};

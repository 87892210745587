import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorRouteComponent } from './error-route/error-route.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/user-forms/user-forms.module').then(
        (m) => m.UserFormsModule
      ),
  },
  {
    path: 'form',
    loadChildren: () =>
      import('./modules/dynamic-forms/dynamic-forms.module').then(
        (m) => m.DynamicFormsModule
      ),
  },
  { path: 'error-page', component: ErrorRouteComponent },
  { path: '**', redirectTo: 'error-page' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsService } from '../modules/dynamic-forms/service/forms.service';

@Component({
  selector: 'app-error-route',
  templateUrl: './error-route.component.html',
  styleUrls: ['./error-route.component.scss'],
})
export class ErrorRouteComponent {
  errorMessage: string | null;

  constructor(private formService: FormsService) {}

  ngOnInit(): void {
    this.errorMessage = this.formService.errorMessage;
    this.formService.errorMessage = '';
  }
}

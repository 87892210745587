export const BASE_URL =
  'https://hxkvp4iltc.execute-api.ap-south-1.amazonaws.com/dev/maruti';
export const environment = {
  production: false,
  baseUrl: `${BASE_URL}/communication-service/v1/`,
  APIKEY: 'LS8LArIgBt34vKrCFOIYp4skbhj7yUAA35UaO2tn',
  FORM_BASE_URL: `${BASE_URL}/form/v1/`,
  PLACEHOLDER_URL: `${BASE_URL}/form-lambda/v1/placeholder`,
  composite: `${BASE_URL}/composite/v1/`,
};

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_URL } from 'src/app/constants/api';
import { BASE_URL, environment } from 'src/environments/environment';
import {
  FormConfigQuestionInterface,
  GetFormInterface,
  GetLanguageResponse,
  GetPlaceHoldersResponse,
  ReportingFormResponse,
  textLanguageMapperInterface,
} from '../interfaces/dynamic-forms.interface';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { questionIdentifier, Regex } from '../constants/dynamic-form.constant';
import { MessageService } from 'primeng/api';
import {
  NewOfferRequest,
  OfferResponse,
} from '../../user-forms/interfaces/common.interface';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  errorMessage: string;
  constructor(
    private readonly http: HttpClient,
    private readonly messageService: MessageService
  ) {}

  getFormPublishLanguage(formCode: string): Observable<GetLanguageResponse[]> {
    return this.http
      .get<{ data: GetLanguageResponse[] }>(
        `${environment.FORM_BASE_URL}${API_URL.language}?formCode=${formCode}`
      )
      .pipe(map((res) => res.data));
  }

  getFormQuestionConfiguration(
    formId: string,
    status: string
  ): Observable<GetFormInterface> {
    return this.http
      .get<{ data: GetFormInterface }>(
        `${environment.FORM_BASE_URL}${API_URL.QUESTION_DETAIL}?formCode=${formId}&status=${status}`
      )
      .pipe(map((res) => res.data));
  }

  getFormTextLanguageConfiguration(
    formId: string,
    status: string,
    languageCode: string
  ): Observable<textLanguageMapperInterface> {
    return this.http
      .get<{ data: textLanguageMapperInterface }>(
        `${environment.FORM_BASE_URL}${API_URL.QUESTION_TEXT_LANG_DETAILS}?formCode=${formId}&status=${status}&language=${languageCode}`
      )
      .pipe(map((res) => res.data));
  }

  bindValidation(
    question: FormConfigQuestionInterface,
    selectedLanguage: string
  ) {
    let validList = [];

    if (question.hidden) {
      return [];
    }
    if (question.isMandatory) {
      validList.push(Validators.required);
    }
    if (question?.validations?.maxLength) {
      validList = [
        ...validList,
        Validators.maxLength(Number(question.validations.maxLength)),
      ];
    }
    if (question?.validations?.minLength) {
      validList = [
        ...validList,
        Validators.minLength(Number(question.validations.minLength)),
      ];
    }
    if (question?.validations?.dataType) {
      switch (question?.validations.dataType.toLowerCase()) {
        case 'alphabet':
          if (question?.validations?.allowSpecialChar) {
            const regex =
              selectedLanguage.toLowerCase() === 'eng'
                ? /^(?=[A-Za-z/\-+.,&()@;:*? {}_]*[A-Za-z])[A-Za-z/\-+.,&()@;:*? {}_]+$/
                : Regex.ALL_LANGUAGE_WITH_SPECIAL_CHAR;
            validList = [...validList, Validators.pattern(regex)];
          } else if (question?.validations?.questionType === 'lqsName') {
            if (
              question.questionIdentifier === questionIdentifier.NAME &&
              question?.validations?.allowedSpecialCharacter?.length
            ) {
              const basePattern = 'A-Za-z';
              const specialChars = question.validations.allowedSpecialCharacter;
              const escapedChars = specialChars
                .map((char) => `\\${char}`)
                .join('');
              const dynamicPattern = new RegExp(
                `^[${basePattern}${escapedChars}]*$`
              );
              validList.push(Validators.pattern(dynamicPattern));
            } else {
              validList.push(Validators.pattern(/^[A-Za-z]*$/));
            }
          } else {
            const regex =
              selectedLanguage.toLowerCase() === 'eng'
                ? /^(?=[A-Za-z ]*[A-Za-z])[A-Za-z ]+$/
                : Regex.ALL_LANGUAGE_WITH_NO_SPECIAL_CHAR;
            validList = [...validList, Validators.pattern(regex)];
          }

          break;
        case 'numeric':
          if (question?.validations?.allowSpecialChar) {
            validList = [
              Validators.pattern(
                /^(?=[0-9/\-+.,&()@;:*? {}_]*[0-9])[0-9/\-+.,&()@;:*? {}_]+$/
              ),
            ];
          } else {
            validList = [
              ...validList,
              Validators.pattern(/^(?=[0-9 ]*[0-9])[0-9 ]+$/),
            ];
          }

          break;
        case 'alphanumeric':
          if (question?.validations?.allowSpecialChar) {
            const regex =
              selectedLanguage.toLowerCase() === 'eng'
                ? /^(?=[A-Za-z0-9/\-+.,&()@;:*? {}_]*[A-Za-z0-9])[A-Za-z0-9/\-+.,&()@;:*? {}_]+$/
                : Regex.ALPHA_NUMERIC_WITH_SPECIAL_CHAR;
            validList.push(...validList, Validators.pattern(regex));
          } else {
            const regex =
              selectedLanguage.toLowerCase() === 'eng'
                ? /^(?=[A-Za-z0-9 ]*[A-Za-z0-9])[A-Za-z0-9 ]+$/
                : Regex.ALPHA_NUMERIC_WITH_NO_SPECIAL_CHAR;
            validList.push(...validList, Validators.pattern(regex));
          }
          break;
      }
    }
    if (
      question.questionIdentifier === questionIdentifier.MOBILE &&
      question?.validations?.startsWith?.length
    ) {
      validList.push(this.startsWithValidator(question.validations.startsWith));
    }
    return validList;
  }

  showError(errorResponse: HttpErrorResponse) {
    if (errorResponse?.status === 504) {
      return 'Currently services are down. Please try again after some time!';
    } else {
      if (errorResponse?.error?.errors?.length) {
        return (
          errorResponse?.error?.errors[0].errorMessage ||
          'Something went wrong!'
        );
      }
    }
  }

  getPlaceHolders(
    formCode: string,
    segmentCode: string,
    urlPlaceholders: { [key: string]: any }
  ): Observable<GetPlaceHoldersResponse> {
    return this.http.post<GetPlaceHoldersResponse>(
      `${environment.PLACEHOLDER_URL}/${segmentCode}/${formCode}`,
      urlPlaceholders
    );
  }
  submitForm(data: any): Observable<ReportingFormResponse> {
    return this.http
      .post<{ data: ReportingFormResponse }>(
        `${BASE_URL}/${API_URL.SUBMIT_DYNAMIC_FORM}`,
        data
      )
      .pipe(map((res) => res.data));
  }

  getSubmitFormStatus(
    placeholders: { [key: string]: any },
    formCode: string,
    segmentCode: string
  ): Observable<ReportingFormResponse> {
    return this.http
      .post<{ data: ReportingFormResponse }>(
        `${BASE_URL}/${API_URL.SUBMIT_FORM_STATUS}?formCode=${formCode}&segmentCode=${segmentCode}`,
        placeholders
      )
      .pipe(map((res) => res.data));
  }

  getApiTypeDropdownOptions(
    apiUrl: string,
    methodType: string,
    requestPayload: any
  ): Observable<any> {
    const url = `${BASE_URL}${apiUrl}`;
    if (methodType === 'GET') {
      return this.http.get(url);
    } else {
      return this.http.post(url, requestPayload);
    }
  }

  startsWithValidator(startsWithArray: number[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        control?.value &&
        !startsWithArray?.some((prefix) =>
          control?.value?.startsWith(prefix.toString())
        )
      ) {
        return { startsWith: { requiredPrefix: startsWithArray.join(', ') } };
      }
      return null;
    };
  }

  showErrorMessage(errorMessage: string): void {
    const messageObj = {
      severity: 'error',
      detail: errorMessage,
    };
    this.messageService.add(messageObj);
  }

  getOffers(payload: NewOfferRequest): Observable<OfferResponse> {
    return this.http.post<OfferResponse>(
      `${environment.composite}${API_URL.NEW_OFFERS_URL}`,
      payload
    );
  }
}

<section class="error-container">
  <img src="assets/images/ms-logo.png" alt="" class="logo" />
  <ng-container *ngIf="errorMessage; else defualtError">
    <div class="alert alert-info text-center" role="alert">
      <h4 class="alert-heading">ERROR</h4>
      <hr />
      <p class="mb-0">
        {{ errorMessage }}
      </p>
    </div>
  </ng-container>
  <ng-template #defualtError>
    <p>
      <b>404.</b>
      That's an error
    </p>
    <p>The requested URL was not found.</p>
  </ng-template>
</section>

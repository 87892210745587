export const API_URL = {
  GET_IMAGE_URL: 'form/getimageurl',
  GET_MODEL_URL: 'dropdown/model',
  GET_VARIANT_URL: 'dropdown/get-variant-by-modelcd',
  GET_STATES_URL: 'dropdown/state',
  GET_CITIES_URL: 'dropdown/city',
  GET_DEALERS_URL: 'form/getwebformdata',
  GET_MAGAZINES_URL: 'form/magazines',
  GET_EPAPERS_URL: 'dropdown/newspaper',
  GET_OFFERS_URL: 'offerPrice/master-offer',
  POST_FORM_URL: 'saveDataInMongo',
  REGISTER_RESPONSE: 'registerresponse',
  QUESTION_DETAIL: 'question/details',
  QUESTION_TEXT_LANG_DETAILS: 'question/text-lang-details',
  language: 'languages',
  SUBMIT_DYNAMIC_FORM: 'reporting/v2/form/response',
  SUBMIT_FORM_STATUS: 'reporting/v2/form/check-form-submission-status',
  GET_LQS_MODEL_DROPDOWN: 'dropdown/lqs-model-master',
  NEW_OFFERS_URL: 'offer/amount',
};

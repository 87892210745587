import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { FormsService } from '../modules/dynamic-forms/service/forms.service';
import { IAPIKeyResponse } from '../modules/user-forms/interfaces/common.interface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { API_URL } from '../constants/api';

@Injectable({
  providedIn: 'root',
})
export class ResolverService implements CanLoad {
  constructor(
    private readonly http: HttpClient,
    private readonly formService: FormsService
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      this.getAPIKeys().subscribe({
        next: (response: IAPIKeyResponse) => {
          if (response.data) {
            sessionStorage.setItem('apiKey', atob(response.data.apiKey));
            observer.next(true);
          } else {
            this.formService.showErrorMessage('API Key not received.');
            observer.next(true);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.formService.showErrorMessage(
            error?.error?.errors?.[0]?.errorMessage || 'Something went wrong!'
          );
          observer.next(true);
        },
      });
    });
  }

  getAPIKeys(): Observable<IAPIKeyResponse> {
    return this.http.get<IAPIKeyResponse>(
      `${environment.composite}${API_URL.API_KEYS}`
    );
  }
}
